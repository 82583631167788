.nav-link {
    font-size: 16px;
    font-weight: 600;
    color:#929AA7;

}

.nav-link:focus, .nav-link:hover {
    font-size: 16px;
    font-weight: 600;
    color: #20BF6B;
}

a.nav-link.active  {
    font-size: 16px;
    font-weight: 600;
    color: #20BF6B;
    border-bottom: 4px solid #20BF6B;
}

.nav-pills .nav-link {
    font-size: 16px;
    font-weight: 600;
    color:#929AA7;
    border-radius: 8px;
    padding: 12px 0;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #20BF6B;   
    border-bottom: 0;
    border-radius: 8px;
}

.table thead {
    background-color: #F0F2F5;
    border-radius: 10px;
    height: 50px;
    vertical-align: middle;
}

thead > tr > th {
    font-size: 12px;
    font-weight: 600;
    color: #666E7D;
    
}

.itemStory:hover{
    background-color: #F0F2F5;
}

.table tbody{
    vertical-align: middle;
}

tbody > tr > td {
    font-size: 12px;
    color: #50545c;
}

tbody > tr > td > img{
    width: 94px;
    height: 130px;
    border-radius: 10px;
}

button.dropdown-toggle, button.dropdown-toggle:hover, button.dropdown-toggle:focus {
    font-size: 16px;
    font-weight: 600;
    background-color: #20BF6B;
    color: white;    
    border-radius: 9px;
    border: none;
    box-shadow: none;
    padding: 10px 30px;
}

button.dropdown-toggle:disabled {
    font-size: 16px;
    font-weight: 600;
    background-color: #666E7D;
    color: white;    
    border-radius: 9px;
    border: none;
    box-shadow: none;
    padding: 10px 30px;
}
.show > .btn-primary.dropdown-toggle {
    color: white; 
    background-color: #20BF6B;
    border: none;
  }


.total-subs-view{
    font-weight: 500;
     font-size: 11px;
     color:#C6CBD4;
}

.item-story:hover{
    background-color: #F0F2F5;
    cursor: pointer;
}

.title-appbar {
    display:inline-block;
    margin: auto 0;
    color: #1E222B;
    font-size: 22px;
    font-weight: 600;
}