.card-chapter{
    box-shadow: 0 0 11.667px -1.17px #261E222A;
    border-radius: 12px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.leading{
    height: 70px;
    width: 70px;
    border-radius: 12px;
    background-color: #20BF6B;
    display: flex;
    color: white;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.title{
    flex: 1;
    font-weight: 600;
    font-size: 18px;
    margin: auto 10px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
