.form-label{
    font-size: 20px;
    font-weight: 600;
}

.form-control{
    font-size: 16px;
    color: #666E7D !important;
    font-weight: 500;
    padding: 15px 25px;
    border-radius: 8px;
}

.form-control:focus{
    color: "#666E7D" !important;
}

.form-control::placeholder{
    font-size: 14px;
    color: #C6CBD4 !important;
    font-weight: 400;
}

h5{
    font-size: 24px;
    font-weight: 600;
}