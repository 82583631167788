.containerImage{
    position: relative;
    margin: 20px 20%;
}

.image{
    width: 100%;
  
}

.close-icon{
    width:25px;
    height:25px;
    background-color:red;
    position:absolute;
    top: 5%;
    right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
}
