/* @tailwind base;
@tailwind components;
@tailwind utilities; */
* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, 'Oswald' sans-serif !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* background-color: "blue"; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
body {
  font-family: 'Poppins';
}


.card{
  border-radius: 6px;
  border: none;
 
  box-shadow: 0 0 28px -11px #401E222B;
}

