.info {
    display: flex;
    justify-content: space-evenly;
}

.containerImg {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}